import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TFunction } from '@wix/yoshi-flow-editor';
import { NO_BREAK_SPACE } from '../constants';

export const getPlanValidityCycle = (plan: PublicPlan, t: TFunction) => {
  if (!plan.pricing) {
    return NO_BREAK_SPACE;
  }
  const isRecurring = !!plan.pricing?.subscription;

  const unlimited = isRecurring ? plan.pricing?.subscription?.cycleCount === 0 : plan.pricing?.singlePaymentUnlimited;
  const unit = isRecurring
    ? plan.pricing?.subscription?.cycleDuration?.unit
    : plan.pricing?.singlePaymentForDuration?.unit;
  const cycleDuration = plan.pricing?.subscription?.cycleDuration?.count || 0;
  const count = isRecurring
    ? plan.pricing?.subscription?.cycleCount || 0
    : plan.pricing?.singlePaymentForDuration?.count || 0;
  const free = plan.pricing?.price?.value === '0';

  if (unlimited && free) {
    return t('pp.plan-validity.free-plan');
  }

  if (!unit) {
    return NO_BREAK_SPACE;
  }

  const planDuration = isRecurring ? cycleDuration * count : count;

  if (!unlimited) {
    switch (unit) {
      case PeriodUnit.DAY:
        return planDuration > 1
          ? t('pp.plan-validity.valid-for-days', { amount: planDuration })
          : t('pp.plan-validity.valid-for-one-day');
      case PeriodUnit.WEEK:
        return planDuration > 1
          ? t('pp.plan-validity.valid-for-weeks', { amount: planDuration })
          : t('pp.plan-validity.valid-for-one-week');
      case PeriodUnit.YEAR:
        return planDuration > 1
          ? t('pp.plan-validity.valid-for-years', { amount: planDuration })
          : t('pp.plan-validity.valid-for-one-year');
      default:
        return planDuration > 1
          ? t('pp.plan-validity.valid-for-months', { amount: planDuration })
          : t('pp.plan-validity.valid-for-one-month');
    }
  }

  return NO_BREAK_SPACE;
};
